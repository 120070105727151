import { cdnUrl } from '@/service/base-url'

import store from '@/store/index.js'

export const isArray = (target) => {
	return toString.call(target) === '[object Array]'
}
export const isUndefined = (target) => {
	return toString.call(target) === '[object Undefined]'
}
export const isObject = (target) => {
	return toString.call(target) === '[object Object]'
}
export const isPromise = (target) => {
	return toString.call(target) === '[object Promise]'
}
export const isString = (target) => {
	return toString.call(target) === '[object String]'
}
export const isEmpty = (target) => {
	if (isUndefined(target)) {
		return true
	} else if (isString(target)) {
		return target.trim().length === 0
	} else if (isObject(target)) {
		return Object.keys(target).length === 0
	} else if (isArray(target)) {
		return target.length === 0
	}
	return false
}
// 对Object/Array的数据判断当字符串、数组、对象为空时不传给后端
export const ignoreEmptyParams = (data) => {
	if (!data) return data
	let copyData = {}
	Object.keys(data).forEach((key) => {
		if (!isEmpty(data[key])) {
			// 为空
			copyData[key] = data[key]
		}
	})
	return copyData
}

// 日期格式化
export function formatDate(date, fmt) {
	if (typeof fmt !== 'string' || fmt === '' || fmt === null || fmt === undefined)
		return date.toString()

	const o = {
		'M+': date.getMonth() + 1, // 月份
		'd+': date.getDate(), // 日
		'h+': date.getHours(), // 小时
		'm+': date.getMinutes(), // 分
		's+': date.getSeconds(), // 秒
		'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
		S: date.getMilliseconds() // 毫秒
	}

	// 年
	if (/(y+)/.test(fmt)) {
		fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
	}
	for (const k in o) {
		if (new RegExp('(' + k + ')').test(fmt)) {
			fmt = fmt.replace(
				RegExp.$1,
				RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length) // 如果两位补0
			)
		}
	}
	return fmt
}

// 关键词飘红
export function contentToRed(keyStr, content) {
	keyStr = keyStr + ''
	content = content + ''

	// 清除前一次的飘红
	content = content.replace(/<span class=\"search-txt-red\">/g, '')
	content = content.replace(/<\/span>/g, '')
	// console.log('清除后', content)
	let keyHtml = `<span class="search-txt-red">${keyStr}</span>`
	let arr = content.split(keyStr)
	let endContent = arr.join(keyHtml)
	return endContent
}

export function heightLight(keyword, string) {
	var reg = new RegExp(keyword, 'gi')
	string = string.replace(reg, function (txt) {
		return "<span class='search-txt-red'>" + txt + '</span>'
	})
	return string
}

// 关键词组偏红
export function heightLightArr(keywordList, string) {
	let keywordreg = keywordList.join('|\\')
	var reg = new RegExp('\\' + keywordreg, 'gi')
	string = string.replace(reg, function (txt) {
		return "<span class='search-txt-red'>" + txt + '</span>'
	})
	return string
}

/* 把JSON转为字符串拼接 */
export function transformRequest(obj, connectStr) {
	let str = []
	for (let p in obj) {
		str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
	}
	return str.join(connectStr)
}

export function setItem(key, data) {
	uni.setStorageSync(key, JSON.stringify(data))
}

export function getItem(key) {
	const value = uni.getStorageSync(key)
	if (value) {
		try {
			return JSON.parse(value)
		} catch (error) {
			return value
		}
	}
	return ''
}

export function delItem(key) {
	if (key) {
		uni.removeStorageSync(key)
	} else {
		uni.clearStorageSync()
	}
}

// 处理分享小程序参数
// 处理小程序路径加上分配所需参数
export const formatParamsDistribution = (path, customer_id, chart_id, source) => {
	let obj = {
		member_id: localStorage.getItem('member_id') || '',
		customer_id: customer_id || '',
		chart_id: chart_id || '',
		source: source
	}
	let tempPath = path
	for (let key in obj) {
		if (obj[key]) {
			tempPath = `${tempPath}&${key}=` + obj[key]
		}
	}
	return Promise.resolve(tempPath)
}
// 获取用户性别图标
export const getUserSexIcon = (type) => {
	const man = ['男', 'man', '1', 1]
	const women = ['女', 'woman', '2', 2]

	if (!type) return ''
	if (man.includes(type)) {
		return `${cdnUrl}img/common/man.png`
	} else if (women.includes(type)) {
		return `${cdnUrl}img/common/women.png`
	} else {
		return ''
	}
}

// 手机号脱敏
export const formatPhone = (phone) => {
	if (!phone) return ''
	let str = phone + ''
	let start = phone.substr(0, 3)
	let end = phone.substr(7)
	return start + '****' + end
}

/**
 *
 * @param {*} func 要进行debouce的函数
 * @param {*} wait 等待时间,默认500ms
 * @param {*} immediate 是否立即执行, 后续的调用不会在执行
 */

export const debounce = (func, wait = 500, immediate = false) => {
	var timeout
	return function () {
		var context = this
		var args = arguments

		if (timeout) clearTimeout(timeout)
		if (immediate) {
			// 如果已经执行过，不再执行
			var callNow = !timeout
			timeout = setTimeout(function () {
				timeout = null
			}, wait)
			if (callNow) func.apply(context, args)
		} else {
			timeout = setTimeout(function () {
				func.apply(context, args)
			}, wait)
		}
	}
}

/**
 *  深度比较两个对象是否相同
 * @param {Object} oldData
 * @param {Object} newData
 */
export function equalsObj(oldData, newData) {
	//       类型为基本类型时,如果相同,则返回true
	if (oldData === newData) return true
	if (
		isObject(oldData) &&
		isObject(newData) &&
		Object.keys(oldData).length === Object.keys(newData).length
	) {
		//      类型为对象并且元素个数相同

		//      遍历所有对象中所有属性,判断元素是否相同
		for (const key in oldData) {
			if (Object.prototype.hasOwnProperty.call(oldData, key)) {
				if (!equalsObj(oldData[key], newData[key]))
					//      对象中具有不相同属性 返回false
					return false
			}
		}
	} else if (isArray(oldData) && isArray(oldData) && oldData.length === newData.length) {
		//      类型为数组并且数组长度相同

		for (let i = 0, length = oldData.length; i < length; i++) {
			if (!equalsObj(oldData[i], newData[i]))
				//      如果数组元素中具有不相同元素,返回false
				return false
		}
	} else {
		//      其它类型,均返回false
		return false
	}

	//      走到这里,说明数组或者对象中所有元素都相同,返回true
	return true
}

export function deepClone(target) {
	// 定义一个变量
	let result
	// 如果当前需要深拷贝的是一个对象的话
	if (typeof target === 'object') {
		// 如果是一个数组的话
		if (Array.isArray(target)) {
			result = [] // 将result赋值为一个数组，并且执行遍历
			for (let i in target) {
				// 递归克隆数组中的每一项
				result.push(deepClone(target[i]))
			}
			// 判断如果当前的值是null的话；直接赋值为null
		} else if (target === null) {
			result = null
			// 判断如果当前的值是一个RegExp对象的话，直接赋值
		} else if (target.constructor === RegExp) {
			result = target
		} else {
			// 否则是普通对象，直接for in循环，递归赋值对象的所有值
			result = {}
			for (let i in target) {
				result[i] = deepClone(target[i])
			}
		}
		// 如果不是对象的话，就是基本数据类型，那么直接赋值
	} else {
		result = target
	}
	// 返回最终结果
	return result
}

export function descToObj(str) {
	const res = str?.split(/\r?\n/)
	// console.log('descToObj', str, res)
	const arr = []
	res?.forEach(item => {
		const label = item.split('*')
		const timer = label[1] || '实时更新'
		const filed = label[0].includes(':') ? label[0].split(':') : label[0].split('：')
		const obj = {
			name: filed[0],
			value: filed[1],
			timer
		}
		arr.push(obj)
	})
	// console.log('descToObj', arr)
	return arr
}

// #ifdef H5
// 初始化时判断是否登录
export function initLogin() {
	let token = localStorage.getItem('token')
	if (token && token !== 'underfind') {
		return Promise.resolve(true)
	}
	store
		.dispatch('user/GET_TOKEN')
		.then(() => {
			return Promise.resolve(true)
		})
		.catch((err) => {
			Promise.reject(true)
			console.log('获取token失败', err)
		})
}

// 重新登陆
export function reLogin() {
	store.commit('user/RESET_STATE')
	store
		.dispatch('user/GET_TOKEN')
		.then(() => {
			return Promise.resolve(true)
		})
		.catch((err) => {
			Promise.reject(true)
			console.log('获取token失败', err)
		})
}

// #endif

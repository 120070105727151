class Request {
	/**
	 * @description 网络请求的默认配置
	 * @property {Object} config - 默认参数配置
	 * @property {string} config.baseUrl - 接口基地址
	 * @property {string} config.business - 接口响应的业务数据对象字段名，默认为data
	 */
	config = {
		/* 返回默认为res.data */
		baseUrl: '',
		// method: 'GET',
		// contentType: 'json',
		business: 'data'
		// dataType: 'json',
		// encoding: 'UTF-8',
		// skipInterceptorResponse: false,
		// slashAbsoluteUrl: true,
		// debug: false,
		// loadingTip: undefined,
		// loadingDuration: 500,
		// responseType: 'text'
	}

	static posUrl(url) {
		/* 判断url是否为绝对路径 */
		return /(http|https):\/\/([\w.]+\/?)\S*/.test(url)
	}

	static getUrl(config) {
		let url = config.url || ''
		let abs = Request.posUrl(url)
		if (!abs) {
			let f = config.slashAbsoluteUrl
			if (f) {
				abs = /^\/([\w.]+\/?)\S*/.test(url)
			}
		}
		return abs ? url : config.baseUrl + url
	}

	/* 
    	设置contentType的类型
     */
	static getContentType(config) {
		var type = config.contentType || 'json'
		var charset = config.encoding || 'UTF-8'
		if (type === 'json') {
			return 'application/json;charset=' + charset
		} else if (type === 'form') {
			return 'application/x-www-form-urlencoded;charset=' + charset
		} else if (type === 'file') {
			return 'multipart/form-data;charset=' + charset
		} else if (type === 'text') {
			return 'text/plain;charset=' + charset
		} else if (type === 'html') {
			return 'text/html;charset=' + charset
		} else {
			throw new Error('unsupported content type : ' + type)
		}
	}

	/**
	 * @property {Object} interceptor 拦截器
	 *
	 */
	interceptor = {
		/**
		 * @description define the interceptor before request
		 * @param {function}
		 */
		request: undefined,
		response: undefined,
		fail: undefined,
		complete: undefined // since 1.2.0
	}

	/**
	 * @description set default request options
	 * @param {Object} config - the default options
	 * @param {string} config.baseUrl baseUrl - the base url
	 * @param {boolean} config.debug debug - enable debug to log
	 */
	setConfig(config) {
		this.config = Object.assign(this.config, config)
	}
	// 请求队列
	pendingRequest = []
	cancelToken(config, task) {
		let params = ''
		if (config.params && typeof config.params === 'object') {
			params = JSON.stringify(config.params)
		}
		if (config.data && typeof config.data === 'object') {
			params = JSON.stringify(config.data)
		}
		let requestMark = ''

		// 区别请求的唯一标识，这里用方法名+请求路径, mustIntercept: 唯一标识不加参数， 针对节流的输入立即搜索
		if (config.mustIntercept) {
			requestMark = `${config.method} ${config.url}`
		} else {
			requestMark = `${config.method} ${config.url} ${params}`
		}
		// const requestMark = `${config.method} ${config.url} ${ params }`
		// 找当前请求的标识是否存在pendingRequest中，即是否重复请求了
		const markIndex = this.pendingRequest.findIndex((item) => {
			return item.name === requestMark
		})
		// 存在，即重复了
		if (markIndex > -1) {
			// 取消上个重复的请求
			this.pendingRequest[markIndex].cancel()
			// 删掉在pendingRequest中的请求标识
			this.pendingRequest.splice(markIndex, 1)
		}
		// 设置自定义配置requestMark项，主要用于响应拦截中
		config.requestMark = requestMark
		// 记录本次请求的标识
		this.pendingRequest.push({
			name: requestMark,
			cancel: this.cancelRequest.bind(task)
		})
		return config
	}
	cancelRequest() {
		this.abort()
	}
	// 取消请求-响应拦截中的处理
	cancelTokenResponse(config) {
		// 根据请求拦截里设置的requestMark配置来寻找对应pendingRequest里对应的请求标识
		const markIndex = this.pendingRequest.findIndex((item) => {
			return item.name === config.requestMark
		})
		// 找到了就删除该标识
		markIndex > -1 && this.pendingRequest.splice(markIndex, 1)
	}
	request(options = {}) {
		var that = this
		if (options.data === undefined || options.data === '') {
			options.data = {}
		}
		if (options.header === undefined) {
			options.header = {}
		}

		let _options = Object.assign({}, this.config, options)
		_options = Object.assign(options, _options)

		_options.url = Request.getUrl(_options)
		if (!_options.header['Content-Type']) {
			_options.header['Content-Type'] = Request.getContentType(_options)
		}
		let _config = _options
		if (that.interceptor.request && typeof that.interceptor.request === 'function') {
			_config = that.interceptor.request(_options)
		}
		let task = undefined
		let promise = new Promise((resolve, reject) => {
			let extras = {}

			that._prepare(that, _config, extras)

			// 文件上传
			if (_config.contentType === 'file') {
				task = uni.uploadFile({
					..._config,
					success: (res) => {
						console.log(res)
						that._success(that, _config, res, resolve, reject)
					},
					fail: (res) => {
						that._fail(that, _config, res, resolve, reject)
					},
					complete: (res) => {
						that._complete(that, _config, res, extras)
					}
				})
				if (_config.progress && typeof _config.progress === 'function') {
					task.onProgressUpdate((_res) => {
						_config.progress(_res, task)
					})
				}
			} else {
				task = uni.request({
					..._config,
					success: (res) => {
						// console.log('request success', res)
						that._success(that, _config, res, resolve, reject)
					},
					fail: (res) => {
						// console.log('request fail', res)
						that._fail(that, _config, res, resolve, reject)
					},
					complete: (res) => {
						// console.log('request complete', res)
						that._complete(that, _config, res, extras)
					}
				})
			}
		})
		if (!_config.closeIntercept) {
			this.cancelToken(_config, task)
		}
		if (_config.success || _config.fail || _config.complete) {
			return task
		}
		return promise
	}

	/**
		 * get请求
     * @method
     * @description execute a get request
     * @param {Object} options - 参数选项
     * @param {string} options.url - 请求地址
     * @param {string} [options.method=GET] - 请求方法 GET|POST
     * @param {string} [options.contentType=json] - 请求类型，为json(默认)，form
     * @param {Object} [options.data] - 请求参数
     * @param {string} [options.encoding] - 请求编码，默认为utf-8
     * @param {string} [options.dataType] - 如果设为 json（默认），会尝试对返回的数据做一次 JSON.parse
     * @param {string} [options.business] - 接口响应的业务数据对象字段名，默认为data，如果返回整个业务对象，则需要设置为undefined
     * @param {string} [options.skipInterceptorResponse] - 是否跳过响应过滤器，如需跳过，请置true
     * @param {string} [options.slashAbsoluteUrl] - 是否视以/开头的url为绝对地址，默认为false，此设置仅当初步判断url为非绝对地址时有效
     * @param {string} [options.loadingTip] - 是否在请求前显示文字为参数值的loading提示，如果是，会在请求结束后自动关闭loading提示
     * @param {string} [options.loadingDuration] - 设置loadingTip时的最小loading显示时间
     * @param {string} [options.closeIntercept] - 是否关闭请求拦截(重复接口请求会取消上一次请求, 标识为url+method+params), , 默认为false
     * @param {string} [options.mustIntercept] - (重复接口请求会取消上一次请求, 标识为url+method), 默认为false
     * 
     * @return {Promise} promise
     * @example
     * $request.get({
         url: 'foo/bar',
         data: {
            param1: value1
         }
     })
     * @see {@link https://uniapp.dcloud.io/api/request/request}
     */
	get(url, data, options = {}) {
		options.method = 'GET'
		options.url = url
		options.data = data
		return this.request(options)
	}

	/**
			 * post请求
			 * @method
			 * @description execute a post request
			 * @param {Object} options - 参数选项
			 * @param {string} options.url - 请求地址
			 * @param {string} [options.method=POST] - 请求方法 GET|POST
			 * @param {string} [options.contentType=json] - 请求类型，为json(默认)，form
			 * @param {Object} [options.data] - 请求参数
			 * @param {string} [options.encoding] - 请求编码，默认为utf-8
			 * @param {string} [options.dataType] - 如果设为 json（默认），会尝试对返回的数据做一次 JSON.parse
			 * @param {string} [options.business] - 接口响应的业务数据对象字段名，默认为data，如果返回整个业务对象，则需要设置为undefined
			 * @param {string} [options.skipInterceptorResponse] - 是否跳过响应过滤器，如需跳过，请置true
			 * @param {string} [options.slashAbsoluteUrl] - 是否视以/开头的url为绝对地址，默认为false，此设置仅当初步判断url为非绝对地址时有效
			 * @param {string} [options.loadingTip] - 是否在请求前显示文字为参数值的loading提示，如果是，会在请求结束后自动关闭loading提示
			 * @param {string} [options.loadingDuration] - 设置loadingTip时的最小loading显示时间
			 * 
			 * @return {Promise} promise
			 * @example
			 * $request.post({
					url: 'foo/bar',
					data: {
							param1: value1
					}
			})
			* @see {@link https://uniapp.dcloud.io/api/request/request}
		*/
	post(url, data, options = {}) {
		options.url = url
		options.data = data
		options.method = 'POST'
		return this.request(options)
	}

	/**
	 * put请求
	 */
	put(url, data, options = {}) {
		options.url = url
		options.data = data
		options.method = 'PUT'
		return this.request(options)
	}
	/**
	 * delete请求
	 */
	delete(url, data, options = {}) {
		options.url = url
		options.data = data
		options.method = 'DELETE'
		return this.request(options)
	}

	/**
		 * 上传文件
     * @method
     * @description execute a get request
     * @param {Object} options - 参数选项
     * @param {string} options.url - 请求地址
     * @param {string} [options.method=GET] - 请求方法 GET|POST
     * @param {string} [options.contentType=json] - 请求类型，为json(默认)，form
     * @param {Object} [options.data] - 请求参数
     * @param {string} [options.encoding] - 请求编码，默认为utf-8
     * @param {string} [options.dataType] - 如果设为 json（默认），会尝试对返回的数据做一次 JSON.parse
     * @param {string} [options.business] - 接口响应的业务数据对象字段名，默认为data，如果返回整个业务对象，则需要设置为undefined
     * @param {string} [options.skipInterceptorResponse] - 是否跳过响应过滤器，如需跳过，请置true
     * @param {string} [options.slashAbsoluteUrl] - 是否视以/开头的url为绝对地址，默认为false，此设置仅当初步判断url为非绝对地址时有效
     * @param {string} [options.loadingTip] - 是否在请求前显示文字为参数值的loading提示，如果是，会在请求结束后自动关闭loading提示
     * @param {string} [options.loadingDuration] - 设置loadingTip时的最小loading显示时间
     * 
     * @return {Promise} promise
     * @example
     * $request.upload({
        url: 'foo/bar',
        filePath: res.tempFilePaths[0];
        data: {
            param1: value1
        }
    })
    * @see {@link https://uniapp.dcloud.io/api/request/network-file}
    */
	upload(options = {}) {
		options.method = 'POST'
		options.contentType = 'file'
		return this.request(options)
	}

	_success = function (that, _config, res, resolve, reject) {
		if (res.statusCode >= 200 && res.statusCode <= 302) {
			// http ok
			var result = res.data // 全局的拦截器
			var parseFileJson =
				_config.contentType === 'file' &&
				typeof result === 'string' &&
				(_config.dataType === undefined || _config.dataType === 'json')
			if (parseFileJson) {
				result = JSON.parse(res.data)
			}
			var skip = _config.skipInterceptorResponse
			// 走全局的拦截器，
			if (
				that.interceptor.response &&
				typeof that.interceptor.response === 'function' &&
				!skip
			) {
				result = that.interceptor.response(result, _config, resolve, reject)
				if (_config.businessSuccess /* || result.success*/) {
					// 不兼容原来的接口业务逻辑调用成功判定
					// 接口调用业务成功
					// var _data = _config.business ? result[_config.business] : result;
					var _data = result
					if (_config.debug) {
						console.log(`response(${_config.url}) success: `, _data)
					}
					_config.success ? _config.success(_data) : resolve(_data)
					return
				} else {
					if (result === undefined) {
						return
					}
				}
			} else {
				// 对于某些特殊接口，比如访问其它系统，全局拦截器可能不适合
				// 这种情况下，需要自己处理接口响应，相当于透传
				if (_config.debug) {
					// console.log(`response(${_config.url }) success: `, result)
				}
				_config.success ? _config.success(result) : resolve(result)
				return
			}
		}
		// 剩下的都走失败
		that._fail(that, _config, res, resolve, reject)
	}

	_fail = function (that, _config, res, resolve, reject) {
		console.log('request _fail', that, _config, res)
		if (_config.debug) {
			// console.error(`response(${_config.url }) failure: `, res)
		}
		if (res.errMsg === 'request:fail abort') {
			return
		}
		var result = res
		if (that.interceptor.fail && typeof that.interceptor.fail === 'function') {
			result = that.interceptor.fail(res, _config)
		}
		if (_config.fail && typeof _config.fail === 'function') {
			_config.fail(result)
		} else {
			return reject(result)
		}
	}

	_prepare = function (that, _config, obj = {}) {
		if (that.interceptor.prepare && typeof that.interceptor.prepare === 'function') {
			that.interceptor.prepare(_config, obj)
			return
		}
		obj.startTime = Date.now()
		if (_config.loadingTip) {
			uni.showLoading({
				title: _config.loadingTip
			})
		}
		if (_config.contentType === 'file') {
			if (_config.formData === undefined || _config.formData === null) {
				_config.formData = _config.data
				delete _config.data
			}
			delete _config.header['Content-Type']
			delete _config.header['Referer']
			_config.method = 'POST'
		}
		if (_config.debug) {
			// console.log(`request(${_config.url }): `, _config)
		}
	}

	_complete = function (that, _config, res, obj = {}) {
		if (that.interceptor.complete && typeof that.interceptor.complete === 'function') {
			that.interceptor.complete(_config, obj, res)
			return
		}
		obj.endTime = Date.now()
		if (_config.debug) {
			// console.log(`request(${_config.url }) completed in ${obj.endTime - obj.startTime} ms`)
		}
		if (_config.loadingTip) {
			let diff = obj.endTime - obj.startTime
			let duration = _config.loadingDuration || 500
			if (diff < duration) {
				diff = duration - diff
			} else {
				diff = 0
			}

			setTimeout(function () {
				uni.hideLoading()
			}, diff)
		}
		if (_config.complete) {
			_config.complete(res)
		}
	}
}
/**
 *
 */
var request = new Request()
/**
 * @module {Request} request
 */
export default request

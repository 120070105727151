var _paq = (window._paq = window._paq || [])
/* tracker methods like "setCustomDimension" should be called before "trackPageView" */
var token_data = localStorage.getItem('user_info')
if (token_data) {
	token_data = JSON.parse(token_data)
	let qw_userid = token_data.qw_userid
	if (qw_userid) {
		_paq.push(['setUserId', qw_userid])
	}
}
_paq.push(['trackPageView'])
_paq.push(['enableLinkTracking'])
;(function () {
	var u = '//tongji.freshgood.cn/'
	_paq.push(['setTrackerUrl', u + 'matomo.php'])
	// 1: 微客, 2: 唐门, 3: 奥斯芬, 4: 私域数科, 5: 不焦先生, 6: 微开, 7: 奢啡
	// 8: 诚信果林, 9: 孙北北, 10: 天使医生, 11: 柚子, 12: 樊文花, 13: 伊丽汇, 14: 泰山啤酒
	_paq.push(['setSiteId', '1'])
	var d = document,
		g = d.createElement('script'),
		s = d.getElementsByTagName('script')[0]
	g.async = true
	g.src = u + 'matomo.js'
	s.parentNode.insertBefore(g, s)
})()
